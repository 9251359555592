<template>
  <div class="ellipse-bg full-screen overflow-auto">
    <div class="d-flex flex-column justify-content-center align-items-center text-center lottie-box">
      <lottie :options="ai" :height="600" :width="600" />
      <span class="text-h1">Welcome to MetalNx</span>
      <span class="text-h4 mt-5">Next-generation AI-Defined Metal Components</span>
    </div>
  </div>
</template>

<script>
import Lottie from 'vue-lottie';

import ai from '@/assets/ai.json'; // Replace with your Lottie file path


export default {
  name: 'CFrame',
  components: {
    Lottie
  },
  data() {
    return {
      ai: {
        loop: true,
        autoplay: true,
        animationData: ai,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      }

    };
  }
};
</script>

<style scoped>
.outerBox {
  margin: 20px;
  border-radius: 6px;
  background: linear-gradient(96.36deg, #9ADBE4 -1.08%, #0393A8 92.31%);
}

.innerBox {
  margin: 1px;
  border-radius: 6px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.32));
}


.full-screen {
  height: 100vh; /* Full screen height */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically */
}

</style>
