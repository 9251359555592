<template>
  <div class="css-vars">
    <div class="d-flex" style="position:relative; z-index:1;">
      <ul class="sidenav" :class="menuCollapsed? 'collapsed' : ''">
        <li>
          <a class="collapseButton mt-5 px-0 ml-3 mr-auto" @click.stop="onMenuCollapseChanged()">
            <icon id="equal-sign" class="mx-auto mb-2"></icon>
          </a>
        </li>
        <li>
          <div class="mt-12 mb-4 ml-6 white--text text-h4">
            <icon id="menu-circle-open"></icon>
          </div>
        </li>

        <li v-for="item in menuItems" :key="item.id">
          <router-link v-if="item.sub.length == 0" class="menuItem" active-class="activeMenu" :class="item.disabled ? 'menuItemDisabled' : ''" :to="item.to" :is="item.disabled ? 'span' : 'router-link'">
            <span class="activeMenuBar"></span>
            <icon :id="item.disabled ? item.icon : item.icon+'-colored'" class="icon" ></icon>
            {{ item.name }}
          </router-link>
          <div :id="item.id"  @mouseenter="onSubmenuMouseEnter(item.id, item.sub.length)" @mouseleave="onSubmenuMouseLeave(item.id)" v-else :class="item.disabled ? 'menuItemDisabled' : ''" style="position: relative">
            <div :id="'header_' + item.id" class="menuItem subMenuHeader " :class="[menuCollapsed ? 'collapsed' : '', item.active? 'activeMenu':'']" @click="onDropdownMenuClick(item.id)">
              <span class="activeMenuBar " ></span>
              <icon :id="item.disabled ? item.icon : item.icon+'-colored'" class="icon"></icon>
              {{ item.name }}
              <icon id="chevron-down" class="float-right mt-1"></icon>
            </div>
            <ul class="css-vars subMenuList " @mouseenter="onSubmenuMouseEnter(item.id, item.sub.length)" @mouseleave="onSubmenuMouseLeave(item.id)"  :id="'menu_'+item.id" :data-target="item.id"  :class="[expandedItem == item.id ? 'visible'+item.sub.length : '', menuCollapsed ? 'collapsed' : '']">
              <div v-if="menuCollapsed" class="h4 subMenuListHeader" :class="  item.active? 'active':''" >{{item.name}}</div>
              <li v-for="subitem in item.sub" :key="subitem.id" @click="onSubmenuMouseClick(item.id)"  >
                <router-link class="menuItem pl-3" active-class="activeMenu"  :class="subitem.disabled ? 'menuItemDisabled' : ''" :to="subitem.to" :is="subitem.disabled ? 'span' : 'router-link'">
                  <!-- <span class="activeMenuBar"></span> -->
                  <!-- <icon :id="subitem.icon" class="icon"></icon> -->
                  {{ subitem.name }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <ul class="sidenav sidenavBottom" :class="menuCollapsed? 'collapsed' : ''">
        <li class="pt-auto">
          <router-link class="menuItem" active-class="activeMenu" to="/panel/profile/changepassword">
            <span class="activeMenuBar"></span>
            <icon class="icon" id="account-outline"></icon>
            Profile
          </router-link>
        </li>
        <li>
          <a class="menuItem" active-class="activeMenu" @click="logout()">
            <span class="activeMenuBar"></span>
            <icon class="icon" id="logout"></icon>
            Logout
          </a>
        </li>
      </ul>
    </div>
    <div class="mainPanel" :class="menuCollapsed ? 'collapsed' : ''">
      <v-slide-x-transition mode="out-in">
        <router-view></router-view>
      </v-slide-x-transition>
    </div>
  </div>
</template>

<script>
import { getPackages } from "@/api/user/dataset";
export default {
  name: "Home",
  computed: {
    menuItems() {
      return this.menuItemData.map((item) => {
        let x = item;
        x.active = false
        if (item.to === this.$route.path || item.sub.findIndex(x => x.to === this.$route.path) > -1){
          x.active = true
        } 
        return x;
      });
    },
  },
  data: () => ({
    expandedItem: null,
    menuCollapsed: false,
    grantedPackages: [],
    menuItemData: [
      {
        id: "HOME",
        name: "HOME",
        icon: "menu-home",
        to: "/user/home",
        disabled: false,
        sub: [],
      },
      {
        id: "I_PREDIX",
        name: "PrediX",
        icon: "menu-ipredix",
        to: "/panel/castingplatform/alloyproperties",
        disabled: false,
        sub: [
          {
            id: "I_PREDIX_CASTING",
            name: "Casting",
            icon: "menu-alloy-design",
            to: "/panel/ipredix/casting",
            disabled: false,
          },
          {
            id: "I_PREDIX_WROUGHT",
            name: "Wrought",
            icon: "menu-alloy-design",
            to: "/panel/ipredix/wrought",
            disabled: true,
          },
          {
            id: "I_PREDIX_ADDITIVE",
            name: "Additive",
            icon: "menu-alloy-design",
            to: "/panel/ipredix/casting",
            disabled: true,
          },
          {
            id: "I_PREDIX_COMPONENT",
            name: "Component",
            icon: "menu-alloy-design",
            to: "/panel/ipredix/casting",
            disabled: true,
          },
        ],
      },
      {
        id: "I_MAT",
        name: "AlloyNx",
        icon: "menu-imat",
        to: "/panel/alloyDesign/casting",
        disabled: false,
        sub: [
          {
            id: "I_MAT_CASTING",
            name: "Casting",
            icon: "menu-imat",
            to: "/panel/alloyDesign/casting",
            disabled: false,
          },
          {
            id: "I_MAT_WROUGHT",
            name: "Wrought",
            icon: "menu-imat",
            to: "/panel/alloyDesign/wrought",
            disabled: true,
          },
          {
            id: "I_MAT_ADDITIVE",
            name: "Additive",
            icon: "menu-imat",
            to: "/panel/alloyDesign/casting",
            disabled: true,
          },
        ],
      },
      {
        id: "I_PRO",
        name: "ProNx",
        icon: "menu-ipro",
        to: "/panel/htdesign",
        disabled: true,
        sub: [],
      },
      {
        id: "I_HEAT",
        name: "HeatNx",
        icon: "menu-iheat",
        to: "/panel/htdesign",
        disabled: true,
        sub: [],
      },
      {
        id: "I_BOOST",
        name: "AiNx",
        icon: "menu-iboost",
        to: "/panel/htdesign",
        disabled: true,
        sub: [],
      },
      {
        id: "I_TRAIN",
        name: "TraiNx",
        icon: "menu-itrain",
        to: "/panel/aimodel",
        disabled: true,
        sub: [],
      },
    ],
  }),
  methods: {
    onDropdownMenuClick(itemId) {
      if (this.menuCollapsed) {
        return;
      }
      this.expandedItem == itemId ? (this.expandedItem = null) : (this.expandedItem = itemId);
    },
    onMenuCollapseChanged() {
      this.menuCollapsed = !this.menuCollapsed;
      // this.expandedItem = null;

      const submenuLists = document.querySelectorAll('.subMenuList')
      submenuLists.forEach(submenuList => {
        if(this.menuCollapsed){
            // submenuList.style.left = `${
              // targetPosition.left + targetPosition.width + 20
            // }px`
          document.body.appendChild(submenuList)
        } else {
          const targetID = submenuList.dataset.target
          const target = document.querySelector(`#${targetID}`)
          if (!target) return
          target.appendChild(submenuList)
        }})
    },
    onSubmenuMouseEnter(Id, ht){
      if (!this.menuCollapsed) {
        return;
      }
      var submenuList = document.getElementById('menu_'+Id)
      const headerID = 'header_' + submenuList.dataset.target
      const header = document.querySelector(`#${headerID}`)
      const targetPosition = header.getBoundingClientRect()
      submenuList.style.top = `${targetPosition.top + window.scrollY }px`
      submenuList.classList.add('popup')
      submenuList.classList.add('ht' + ht)
    },
    onSubmenuMouseClick(id){
      // console.log(this.$router.currentRoute)
      this.onSubmenuMouseLeave(id)
    },
    onSubmenuMouseLeave(Id){
      if (!this.menuCollapsed) {
        return;
      }
      var submenuList = document.getElementById('menu_'+Id)
      submenuList.classList.remove('popup')
    },    
    logout() {
      this.$store.commit("logout");
      this.$router.push("/login");
    },
    loadPackages() {
      const _this = this;
      getPackages(function (res) {
        _this.grantedPackages = res;
        for (var pkg of res) {
          if (pkg.name.endsWith("_ALLOY_DESIGN")) {
            _this.menuItems[_this.menuItems.findIndex((item) => item.id === "ALLOY_DESIGN")].disabled = false;
          } else if (pkg.name.startsWith("HT_")) {
            _this.menuItems[_this.menuItems.findIndex((item) => item.id === "HT_DESIGN")].disabled = false;
          } else {
            _this.menuItems[_this.menuItems.findIndex((item) => item.id === pkg.name)].disabled = false;
          }
        }
      });
    },
  },
  created() {
    // this.loadPackages();
  },

  //   mounted: function() {
  //   let elHtml = document.getElementsByTagName('html')[0]
  //   elHtml.style.overflowY = 'hidden'
  // },
  // destroyed: function() {
  //   let elHtml = document.getElementsByTagName('html')[0]
  //   elHtml.style.overflowY = null
  // }
};
</script>
<style scoped>

.css-vars {
  --sidebar-width: 260px;
  --sidebar-collapsed-width: 72px;
  --transition: all 0.5s ease;
  --menu-font-family: "KoHo" ;
  --menu-font-size: 18px;
  --menu-font-weight: 700;
  /* --menu-collapsed-font-size: 18px; */
  /* --menu-collapsed-font-weight: 700; */
  
  --submenu-font-size: 16px;
  --submenu-font-weight: 500;
}
div.content {
  margin-left: var(--sidebar-width);
}

.sidenav {
  font-family: var(--menu-font-family);
  list-style-type: none;
  margin: 0;
  padding: 2px;
  background-color: black;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  width: var(--sidebar-width);
  overflow-x: hidden;
  transition: var(--transition);
  /* transition: font-size 0.7s ease; */
  font-size: var(--menu-font-size);
  font-weight: var(--menu-font-weight);
}
.sidenav.collapsed{
  width: var(--sidebar-collapsed-width);
  /* font-size: var(--menu-collapsed-font-size); */
  /* font-weight: var(--menu-collapsed-font-weight); */
}
.sidenavBottom {
  bottom: 10px;
  height: auto;
}
.sidenavBottom.collapsed{
  width: var(--sidebar-collapsed-width);
}
.menuItem {
  display: block;
  padding: 12px 0px 12px 12px;
  margin-right: 4px;
  text-decoration: none;
  color: white;
  transition: all 0.5s;
  width: calc(var(--sidebar-width) - 10px);
  position: relative;
}
.icon{
 margin-right: 18px;
 margin-left: 1px;
 font-size: 23px;
}

.menuItem:hover:not(.activeMenu):not(.menuItemDisabled) {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  cursor: pointer;
}

.menuItem:hover:not(.menuItemDisabled) {
  cursor: pointer;
}

.subMenuList {
  list-style-type: none;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  padding-left: 32px;
  font-weight: var(--submenu-font-weight);
  font-size: var(--submenu-font-size);

  /* margin-top: 2px; */
  /* transition: overflow 0.4s ease-out 2s ; */
  /* transition-delay: visibility 6s ; */
  /* -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in;
  -o-transition: all 1s ease-in;
  -ms-transition: all 1s ease-in;   */
  height: 0;
  overflow: hidden;
}
.subMenuListHeader{
  font-weight: 700;
  color: white !important;
  /* color:var(--v-primary-base) !important; */
  margin: 5px 5px 20px 5px !important;
}

.subMenuListHeader.active{
    color:var(--v-primary-base) !important;
}

.subMenuList.visible4 {
  height: 190px;
  overflow: visible;
}
.subMenuList.visible3 {
  overflow: visible;
  height: 140px;
}

.subMenuList.collapsed {
  position: absolute;
  overflow: hidden;
  opacity: 0;
  /* font-weight: var(--menu-font-weight); */
  font-size: var(--submenu-font-size);
  font-family: var(--menu-font-family);
  left: 72px;
  width: 180px;
}

.subMenuList.popup {
  z-index: 99;
  border-radius: 0px 8px 8px 0px;
  padding: 10px 6px 10px 8px;
  background-color: black;
  /* margin-left: 20px; */
  height: 148px;
  overflow: visible;
  opacity: 1;
}


.subMenuList.popup.ht3 {
  height: 190px;
}
.subMenuList.popup.ht4 {
  height: 230px;
}


.subMenuList .menuItem {
  padding: 10px 2px 10px 20px;
}

.menuItemDisabled {
  color: #6B7588;
;
}

.activeMenu {
  color: var(--v-primary-base);
}
.activeMenuBar {
  border-radius: 0px 4px 4px 0px !important;
  border-width: 3px !important;
  border-right-style: solid;
  border-color: var(--v-primary-base);
  margin-right: 8px;
  opacity: 0;
}
.activeMenu .activeMenuBar {
  opacity: 100;
}

.collapseButton {
  display: inline-block;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  background-color: var(--v-primary-base);
  color: white;
  font-size: 20px;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
}

.mainPanel {
  padding: 0 0 0 var(--sidebar-width);
  transition: var(--transition);
}
.mainPanel.collapsed {
  padding: 0 0 0 calc(var(--sidebar-collapsed-width) );
}

</style>
